import "../styles/global.css";
import Layout from "@/components/layout";
import { AnimatePresence, m } from "framer-motion";
import { LazyMotion, domAnimation } from "framer-motion";
import GraphqlProvider from "@/lib/provider";
import { ManagedUIContext } from "@/contexts/ui.context";
import ManagedDrawer from "@/components/drawer/managed-drawer";
import { DefaultSeo } from "next-seo";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function MyApp({ Component, pageProps: { ...pageProps }, router }) {
  return (
    <AnimatePresence>
      <ManagedUIContext>
        <GraphqlProvider>
          <LazyMotion features={domAnimation}>
            <DefaultSeo
              title="Rose Bowl | Shop Now"
              defaultTitle="Rose Bowl | Shop Now"
              description="Explore Our Glassworks. Browse our exclusive range of handcrafted glass platters and T-lights to illuminate your spaces."
              canonical="https://rosebowl.in/"
              openGraph={{
                url: "https://rosebowl.in/",
                title: "Rose Bowl",
                description:
                  "Explore Our Glassworks. Browse our exclusive range of handcrafted glass platters and T-lights to illuminate your spaces.",
                images: [
                  {
                    url: "https://rosebowl.in/images/rosebowl-logo-512x512.webp",
                    width: 140,
                    height: 140,
                    alt: "Rose Bowl",
                  },
                ],
              }}
              twitter={{
                // handle: "@avneesh0612",
                // site: "@avneesh0612",
                cardType: "summary_large_image",
              }}
              additionalLinkTags={[
                {
                  rel: "icon",
                  href: `/favicon.ico`,
                },
              ]}
            />
            <Layout>
              <m.div
                key={router.route}
                initial={{ opacity: 0 }}
                animate={{ opacity: 100, transition: { duration: 0.35 } }}
                exit={{ opacity: 0 }}
                className="h-auto w-full"
              >
                <Component {...pageProps} key={router.route} />
              </m.div>
              <ToastContainer />
              <ManagedDrawer />
            </Layout>
          </LazyMotion>
        </GraphqlProvider>
      </ManagedUIContext>
    </AnimatePresence>
  );
}

export default MyApp;
