import React from "react";

export default function LongArrowRight({ className }) {
  return (
    <svg fill="none" viewBox="0 0 20 19" className={className}>
      <path
        stroke="currentColor"
        strokeWidth="2"
        d="M9.5 1 18 9.5 9.5 18M17.5 9.5H0"
      ></path>
    </svg>
  );
}
