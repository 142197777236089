import React from "react";
import { useUI } from "@/contexts/ui.context";
export default function Hamburger({ color = "bg-white" }) {
  const genericHamburgerLine = `h-[1.5px] shrink-0 ${color} rounded-xl transition ease transform duration-300`;
  const { openSidebar, displaySidebar } = useUI();
  return (
    <button
      className={`left-auto ml-2 w-[24px] h-[21.5px] lg:hidden flex flex-col justify-around items-center group outline-none overflow-hidden focus:outline-none transform transition-all duration-300 ease delay-100`}
      aria-label="menu-button"
      onClick={openSidebar}
    >
      <div
        className={`${genericHamburgerLine} w-full mr-auto ${
          displaySidebar
            ? "rotate-45 translate-y-[7px] translate-x-0"
            : "rotate-0"
        }`}
      />
      <div
        className={`${genericHamburgerLine} w-2/3 ml-auto ${
          displaySidebar ? "opacity-0 -translate-x-2" : "opacity-100"
        } `}
      />
      <div
        className={`${genericHamburgerLine} ${
          displaySidebar
            ? "-rotate-45 -translate-y-[7px] -translate-x-0 opacity-100 mr-0 w-full"
            : "w-1/2"
        } ml-auto`}
      />
    </button>
  );
}
