import React, { useRef, useState, useEffect } from "react";
import Hamburger from "@/components/ui/hamburger";
import { siteSettings } from "@/settings/site-settings";
import HeaderMenu from "./header-menu";
import Logo from "@/components/ui/logo";
import { useUI } from "@/contexts/ui.context";
import SearchIcon from "@/icons/search-icon";
import cn from "classnames";
import useScreenSize from "@/utils/use-screen-size";
const { site_header } = siteSettings;
import { m } from "framer-motion";
import { useRouter } from "next/router";
const Header = () => {
  const router = useRouter();
  const { openSearch, openModal, setModalView } = useUI();
  const siteHeaderRef = useRef();

  const size = useScreenSize();

  function handleLogin() {
    setModalView("LOGIN_VIEW");
    return openModal();
  }

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const hiddenSlugs = [
    "/products",
    "/terms-and-conditions",
    "/contact-us",
    "/about-us",
  ];
  return (
    <m.header
      initial={{ top: size?.width > 991 ? 40 : 32 }}
      animate={{ top: scrollPosition > 0 ? 0 : size?.width > 991 ? 40 : 32 }}
      transition={{ duration: 0.5, ease: "linear" }}
      id="siteHeader"
      ref={siteHeaderRef}
      className="z-20 w-full lg:h-[120px] h-[100px] fixed top-0 group/header flex flex-col items-center justify-center"
    >
      <div
        className={`z-20 w-full h-full group-hover/header:bg-beige transition duration-500 ease-in-out lg:px-[5vw] md:px-8 px-4 ${
          scrollPosition > 0 ||
          hiddenSlugs.some((slug) => router?.pathname.startsWith(slug))
            ? "bg-darkBeige bg-opacity-100"
            : "bg-transparent bg-opacity-0"
        } body-font h-full lg:py-0 `}
      >
        <div className="flex items-center justify-between h-full w-full">
          <Hamburger
            color={cn(
              `group-hover/header:bg-darkBrown transition-all duration-300 ease`,
              {
                "bg-darkBrown":
                  scrollPosition > 0 ||
                  hiddenSlugs.some((slug) => router?.pathname.startsWith(slug)),
                "bg-white":
                  scrollPosition <= 0 &&
                  !hiddenSlugs.some((slug) =>
                    router?.pathname.startsWith(slug)
                  ),
              }
            )}
          />

          <HeaderMenu
            data={site_header.menu}
            scrollPosition={scrollPosition}
            className={cn(
              `hidden lg:flex items-center text-[#fdf0e6] group-hover/header:text-darkBrown h-full`,
              {
                "text-darkBrown":
                  scrollPosition > 0 ||
                  hiddenSlugs.some((slug) => router?.pathname.startsWith(slug)),
              }
            )}
          />
          <button
            className="lg:hidden pl-4"
            onClick={openSearch}
            aria-label="search-button"
          >
            <SearchIcon
              className={cn(
                `group-hover/header:text-darkBrown transition-all duration-300 ease`,
                {
                  "text-darkBrown":
                    scrollPosition > 0 ||
                    hiddenSlugs.some((slug) =>
                      router?.pathname.startsWith(slug)
                    ),
                  "text-white":
                    scrollPosition <= 0 &&
                    !hiddenSlugs.some((slug) =>
                      router?.pathname.startsWith(slug)
                    ),
                }
              )}
            />
          </button>
          <div className="w-fit flex shrink-0 items-center justify-center absolute top-1/1 lg:left-[calc(50%-40px)] md:left-[calc(50%-70px)] left-[calc(50%-50px)]">
            <Logo
              className={cn(
                `group-hover/header:text-darkBrown transition-all duration-300 ease`,
                {
                  "text-darkBrown":
                    scrollPosition > 0 ||
                    hiddenSlugs.some((slug) =>
                      router?.pathname.startsWith(slug)
                    ),

                  "text-white":
                    scrollPosition <= 0 &&
                    !hiddenSlugs.some((slug) =>
                      router?.pathname.startsWith(slug)
                    ),
                }
              )}
            />
          </div>

          <button
            className="lg:block hidden"
            onClick={openSearch}
            aria-label="search-button"
          >
            <SearchIcon
              className={cn(
                `group-hover/header:text-darkBrown transition-all duration-300 ease`,
                {
                  "text-darkBrown":
                    scrollPosition > 0 ||
                    hiddenSlugs.some((slug) =>
                      router?.pathname.startsWith(slug)
                    ),
                  "text-white":
                    scrollPosition <= 0 &&
                    !hiddenSlugs.some((slug) =>
                      router?.pathname.startsWith(slug)
                    ),
                }
              )}
            />
          </button>
        </div>
      </div>
      <div
        className={`border-b-[1.5px] left-[0] z-[1000] group-hover/header:w-full mx-auto transition-all duration-500 ease-out group-hover/header:border-darkBrown ${
          scrollPosition > 0 ||
          hiddenSlugs.some((slug) => router?.pathname.startsWith(slug))
            ? "w-[100%] border-darkBrown"
            : "lg:w-[90%] w-[95%] border-white"
        }`}
      ></div>
    </m.header>
  );
};

export default Header;
