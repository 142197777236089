import Link from "next/link";
import Image from "next/image";

const CategoryCard = ({ data }) => {
  const { label, image, path } = data ?? {};
  const placeholderImage = `/images/placeholder.svg`;
  const imageSrc =
    image?.data?.attributes?.formats?.small?.url ??
    image?.data?.attributes?.url ??
    placeholderImage;

  return (
    <div
    // initial={{ opacity: 0 }}
    // whileInView={{ opacity: 100 }}
    // transition={{ duration: 0.2 }}
    // className="categoryContainer"
    >
      <Link
        href={path}
        className="group flex justify-center items-center group/card text-center flex-col gap-1"
      >
        <div className="flex md:w-[110px] md:h-[110px] h-[85px] w-[85px] items-center relative rounded-full overflow-hidden shrink-0 border-[1.5px] border-darkBrown">
          <Image
            src={imageSrc}
            alt={label ?? "category-thumbnail"}
            fill
            quality={80}
            sizes="(max-width: 768px) 300px, (max-width: 1200px) 300px, 300px"
            className={`object-cover bg-gray-300 rounded-full lg:group-hover/card:scale-105 transition-all duration-300 ease`}
          />
        </div>

        <p
          variant="subTitle"
          className="capitalize w-fit font-medium categoryTitle relative inline-block text-darkBrown"
        >
          {label ?? "loading..."}
        </p>
      </Link>
    </div>
  );
};

export default CategoryCard;
