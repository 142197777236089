const SearchIcon = ({
  color = "currentColor",
  width = "20",
  height = "20",
  className = "",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      className={className}
    >
      <path
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="1.7"
        d="m20.166 20.167-5.814-5.815M9.165 16.5a7.333 7.333 0 1 0 0-14.667 7.333 7.333 0 0 0 0 14.667Z"
      ></path>
    </svg>
  );
};

export default SearchIcon;
