import { useState } from "react";
import { siteSettings } from "@/settings/site-settings";
import Link from "next/link";
import Image from "next/image";
import Logo from "@/components/ui/logo";
import { useUI } from "@/contexts/ui.context";
import cn from "classnames";
import EmailIcon from "@/icons/email-icon";
import Linkedin from "@/icons/linkedin";
import Whatsapp from "@/icons/whatsapp";
import Instagram from "@/icons/instagram";
import CloseIcon from "@/icons/close-icon";
import { m, AnimatePresence } from "framer-motion";
import ChevronDown from "@/icons/chevron-down";
import Scrollbar from "@/components/ui/scrollbar";
const iconClass = "md:w-6 w-5";
const social = [
  {
    id: 1,
    link: "https://www.instagram.com/rosebowl.in/profilecard/?igsh=MWFxYnd2bnd2dHFjNQ==",
    icon: <Instagram className={iconClass} />,
    title: "Instagram",
  },
  {
    id: 2,
    link: "https://www.linkedin.com/in/rajeev-babbar-366ab78",
    icon: <Linkedin className={iconClass} />,
    title: "Linkedin",
  },
  {
    id: 3,
    link: "https://api.whatsapp.com/send?phone=919818314184",
    icon: <Whatsapp className={iconClass} />,
    title: "Whatsapp",
  },

  {
    id: 4,
    link: "mailto:rajeev@rosebowl.in",
    icon: <EmailIcon className={iconClass} />,
    title: "Email",
  },
];

export default function MobileMenu() {
  const { site_header } = siteSettings;
  const { closeSidebar } = useUI();

  return (
    <>
      <div className="flex flex-col justify-start w-full h-full bg-beige">
        <div className="w-full bg-darkBeige flex justify-between items-center relative pl-4 md:pl-12 flex-shrink-0 md:py-2 py-0.5">
          <Logo
            className={cn(`text-darkBrown transition-all duration-300 ease`)}
          />

          <button
            className="flex items-center justify-center md:px-8 px-4 py-4 transition-opacity focus:outline-none hover:opacity-60 opacity-80"
            onClick={closeSidebar}
            aria-label="close"
          >
            <CloseIcon className="w-4 text-darkGrey" />
          </button>
        </div>

        <Scrollbar className="flex-grow mb-auto menu-scrollbar">
          <div className="flex flex-col w-full items-start justify-start py-7 lg:px-2 text-heading px-4 md:px-8">
            {site_header.menu.map((menu, index) =>
              menu?.subMenu ? (
                <Accordion
                  title={menu?.label}
                  expanded={false}
                  key={"menu" + index}
                >
                  <div className="w-full grid grid-cols-3 gap-6 auto-rows-auto bg-darkBeige rounded-2xl p-3">
                    {menu?.subMenu?.map((row, index) => {
                      return (
                        <Link
                          href={row?.path}
                          key={"caategory" + index}
                          onClick={closeSidebar}
                          className="group flex justify-start items-center group/card text-center flex-col gap-1"
                        >
                          <div className="flex md:w-[110px] md:h-[110px] h-[85px] w-[85px] items-center relative rounded-full overflow-hidden shrink-0 border-[1.5px] border-darkBrown">
                            <Image
                              src={row?.image?.data?.attributes?.url}
                              alt={row?.label ?? "category-thumbnail"}
                              fill
                              quality={80}
                              sizes="(max-width: 768px) 300px, (max-width: 1200px) 300px, 300px"
                              className={`object-cover bg-gray-300 rounded-full lg:group-hover/card:scale-105 transition-all duration-300 ease`}
                            />
                          </div>

                          <p
                            variant="subTitle"
                            className="capitalize w-fit font-medium categoryTitle relative inline-block text-darkBrown"
                          >
                            {row?.label}
                          </p>
                        </Link>
                      );
                    })}
                  </div>
                </Accordion>
              ) : (
                <Link
                  key={"link" + index}
                  href={menu?.path}
                  onClick={closeSidebar}
                  className="transition-all duration-300 ease text-darkGrey font-semibold text-lg max-w-full mt-4"
                >
                  {menu?.label}
                </Link>
              )
            )}
          </div>
        </Scrollbar>

        <div className="mt-auto flex items-center gap-6 justify-center flex-shrink-0 bg-inherit border-t border-darkGrey/20 px-4 md:px-8">
          {social?.map((item, index) => (
            <a
              href={item.link}
              className={`text-darkGrey transition py-3 duration-300 ease-in`}
              target="_blank"
              key={index}
            >
              <span className="sr-only">{item.title}</span>
              {item.icon}
            </a>
          ))}
        </div>
      </div>
    </>
  );
}
const Accordion = ({ children, initial = false, title }) => {
  const [open, setOpen] = useState(initial ?? false);
  return (
    <div
      className={`px-0 w-full group flex flex-col z-0 last:border-b-0 ${
        open ? "border-transparent" : "border-[#707070]/20"
      } border-b-[1px] overflow-hidden cursor-pointer`}
    >
      <div
        className="flex flex-row justify-between items-center py-4 h-auto w-full relative"
        onClick={() => setOpen(!open)}
      >
        <p
          className={`transition-all duration-300 ease text-darkGrey font-semibold text-lg max-w-full`}
        >
          {title}
        </p>
        <div
          className={`w-3 h-3 transition-all text-body duration-400 ease transform mr-2 ${
            !open ? "rotate-0" : "-rotate-180"
          } relative`}
        >
          <ChevronDown />
        </div>
      </div>
      <AnimatePresence initial={false}>
        {open && (
          <m.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { height: "auto", opacity: 1 },
              collapsed: { height: "0px", opacity: 0 },
            }}
            transition={{ duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <m.div
              variants={{ collapsed: { opacity: 0.8 }, open: { opacity: 1 } }}
              transition={{ duration: 0.3 }}
              className="w-full"
            >
              {children}
            </m.div>
          </m.section>
        )}
      </AnimatePresence>
    </div>
  );
};
