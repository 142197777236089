import React, { useRef, useState, useEffect } from "react";
import { m } from "framer-motion";
import useOnClickOutside from "@/utils/use-click-outside";
import { useForm } from "react-hook-form";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import LongArrowRight from "@/icons/long-arrow-right";
const SubscribeForm = ({ status, message, onValidated }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();
  const [active, setActive] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setActive(false));
  useEffect(() => {
    if (status === "success") clearFields();
  }, [status]);
  const clearFields = () => {
    reset();
  };
  function onSubmit(data) {
    data.email &&
      data.email.indexOf("@") > -1 &&
      onValidated({
        MERGE0: data.email,
      });
  }
  return (
    <form
      className="flex w-full items-center justify-center mt-4"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      {status === "error" && (
        <div
          className="text-center text-red text-sm w-full text-red-500"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}

      {status === "success" && (
        <div
          className="text-center text-white w-full border-dashed border-[1px] border-white rounded md:p-8 px-4 py-8 md:text-base text-sm font-medium"
          dangerouslySetInnerHTML={{
            __html: "Thank you for reaching out!",
          }}
        />
      )}
      {status !== "success" && status !== "error" && (
        <div className="flex flex-col w-full items-center justify-center">
          <div
            ref={ref}
            className="rounded-full overflow-hidden pl-4 flex items-center justify-start relative bg-[#232220] border-[1px] px-1 h-[50px] border-beige w-full"
          >
            <input
              {...register("email", {
                required: `${"Please provide your email address"}`,
                pattern: {
                  value:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Please provide a valid email address",
                },
              })}
              onClick={() => setActive(true)}
              type="email"
              placeholder="Type in your email"
              className={`bg-[#232220] ring-0 outline-none decoration-none w-full text-sm text-beige placeholder:text-beige text-left font-light transition-all duration-500 ease`}
            />

            <button
              type="submit"
              className="shrink-0 w-[38px] h-[38px] rounded-full hover:bg-black bg-beige text-darkgrey hover:text-beige transition-all duration-500 ease flex items-center justify-center"
            >
              <LongArrowRight className="w-4" />
            </button>
          </div>
          {errors?.email?.message && (
            <p className="text-sm text-red-500 text-left w-full px-4 pt-1">
              {errors?.email?.message}
            </p>
          )}
        </div>
      )}
    </form>
  );
};
const Subscribe = (props) => {
  const url = `https://gmail.us10.list-manage.com/subscribe/post?u=fe2e4ac54fbb9f00f7b172c0e&id=8fbdae23a9`;

  return (
    <div className="w-full z-[1]">
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <SubscribeForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
};

export default Subscribe;
