export const siteSettings = {
  name: "Rose Bowl",
  title: "Rose Bowl | Premium and Limited.....",
  description:
    "Shop From A Wide Range of Brands: Jordans, Yeezys, Dunks, Fear of God Essentials, Limited Edition & Authentic Products Online With Fast & Safe Delivery.",
  websiteUrl: "https://rosebowl.in/",
  author: {
    name: "Dhruv Babbar",
    websiteUrl: "https://rosebowl.in",
    address: "",
  },
  logo: {
    url: "/Rosebowlpink.svg",
    alt: "RoseBowl",
    path: "/",
    width: 105,
    height: "auto",
  },
  defaultLanguage: "en",
  currencyCode: "INR",
  site_header: {
    menu: [
      {
        id: 1,
        label: "Serve-in-Style",
        subMenu: [
          {
            id: 1,
            path: "/category/lazy-susan",
            label: "Lazy Susan",
            image: {
              data: {
                attributes: {
                  url: "/images/categories/lazy-suzan.webp",
                  alternativeText: "",
                },
              },
            },
          },
          {
            id: 2,
            path: "/category/shovel",
            label: "Shovel",
            image: {
              data: {
                attributes: {
                  url: "/images/categories/shovel-category.webp",
                  alternativeText: "",
                },
              },
            },
          },
          {
            id: 3,
            path: "/category/pebbles",
            label: "Pebbles",
            image: {
              data: {
                attributes: {
                  url: "/images/categories/Pebbles_Platter_category.webp",
                  alternativeText: "",
                },
              },
            },
          },
          {
            id: 4,
            path: "/category/platter",
            label: "Platter",
            image: {
              data: {
                attributes: {
                  url: "/images/categories/platter.webp",
                  alternativeText: "",
                },
              },
            },
          },
          {
            id: 5,
            path: "/category/tray",
            label: "Tray",
            image: {
              data: {
                attributes: {
                  url: "/images/categories/tray_category.webp",
                  alternativeText: "",
                },
              },
            },
          },
          {
            id: 6,
            path: "/category/fruit-bowl",
            label: "Fruit Bowl",
            image: {
              data: {
                attributes: {
                  url: "/images/categories/fruit-bowl.webp",
                  alternativeText: "",
                },
              },
            },
          },
        ],
      },
      {
        id: 2,
        label: "Home Decor",
        subMenu: [
          {
            id: 1,
            path: "/category/tulip",
            label: "Tulip",
            image: {
              data: {
                attributes: {
                  url: "/images/categories/tulip_category.webp",
                  alternativeText: "",
                },
              },
            },
          },
          {
            id: 2,
            path: "/category/swirl",
            label: "Swirl",
            image: {
              data: {
                attributes: {
                  url: "/images/categories/swirl_category.webp",
                  alternativeText: "",
                },
              },
            },
          },
          {
            id: 3,
            path: "/category/table-clock",
            label: "Table Clock",
            image: {
              data: {
                attributes: {
                  url: "/images/categories/Table_Clock_category.webp",
                  alternativeText: "",
                },
              },
            },
          },
          {
            id: 4,
            path: "/category/t-light",
            label: "T-Light",
            image: {
              data: {
                attributes: {
                  url: "/images/categories/t-light.webp",
                  alternativeText: "",
                },
              },
            },
          },
          {
            id: 5,
            path: "/category/trivet",
            label: "Trivet",
            image: {
              data: {
                attributes: {
                  url: "/images/categories/Trivet Coaster.webp",
                  alternativeText: "",
                },
              },
            },
          },
          {
            id: 6,
            path: "#",
            label: "Candles (Coming Soon)",
            image: {
              data: {
                attributes: {
                  url: "/images/categories/candles-category.webp",
                  alternativeText: "",
                },
              },
            },
          },
        ],
      },
      {
        id: 3,
        path: "/about-us",
        label: "About Us",
      },
    ],
  },
};
