import { createClient, cacheExchange, fetchExchange } from "@urql/core";
import * as React from "react";

const useClient = (options) => {
  // const handleError = useErrorHandler();

  return React.useMemo(() => {
    const client = createClient({
      url: `${process.env.NEXT_PUBLIC_STRAPI_URL}/graphql`,
      exchanges: [cacheExchange, fetchExchange],
      fetchOptions: () => {
        return {
          headers: {
            ...(options?.headers ? options.headers : {}),
          },
        };
      },
    });

    return client;
  }, [options]);
};

export default useClient;
