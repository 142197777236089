import React, { useState, useEffect } from "react";
import Link from "next/link";
import { m } from "framer-motion";
export default function TopBar() {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <m.div
      initial={{ top: 0, opacity: 100 }}
      animate={{
        top: scrollPosition > 0 ? -40 : 0,
        opacity: scrollPosition > 0 ? 0 : 100,
      }}
      transition={{ duration: 0.3, ease: "linear" }}
      className="z-[100] lg:h-10 h-8 fixed top-0 flex items-center justify-center text-white md:text-sm text-[10px] font-medium text-center w-full bg-[#3c322d]"
    >
      FREE SHIPPING ABOVE 3,999/- | SHOP NOW
    </m.div>
  );
}
