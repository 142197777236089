import Image from "next/image";
import Link from "next/link";
import cn from "classnames";
import { siteSettings } from "@/settings/site-settings";
import LogoIcon from "@/icons/logo-icon";
const Logo = ({ className, position = "left", ...props }) => {
  return (
    <Link href="/" className="flex flex-col justify-center items-center">
      <div
        className={cn(
          "inline-flex focus:outline-none lg:w-20 lg:h-16 md:h-12 h-16 md:w-[100px] w-[80px] relative ",
          className
        )}
        {...props}
      >
        <LogoIcon />
      </div>
      <p
        className={cn(
          "uppercase font-cardo md:text-[15px] text-[9px] tracking-[5px] md:mt-2 shrink-0 flex text-center",
          className
        )}
      >
        Rose Bowl
      </p>
    </Link>
  );
};

export default Logo;
