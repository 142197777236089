import * as React from "react";
import Footer from "./footer";
import Header from "./header";
import { Cardo } from "next/font/google";
import localFont from "next/font/local";
import TopBar from "./header/top-bar";
import Search from "../search";

const cardo = Cardo({
  weight: ["400", "700"],
  style: ["normal"],
  subsets: ["latin"],
  display: "swap",
  variable: "--font-cardo",
});
const glacial = localFont({
  src: [
    {
      path: "../../../public/fonts/GlacialIndifference-Regular.otf",
      weight: "400",
      style: "normal",
    },
  ],
  variable: "--font-glacial",
});
const safira = localFont({
  src: [
    {
      path: "../../../public/fonts/Safira March.otf",
      weight: "400",
      style: "normal",
    },
  ],
  variable: "--font-safira",
});

const Layout = ({ children }) => {
  return (
    <div
      className={`w-full h-auto block relative bg-beige min-h-screen ${cardo.variable} font-glacial ${glacial.variable} ${safira.variable}`}
    >
      <TopBar />
      <Header />
      <main className={``}>{children}</main>
      <Footer />
      <Search />
    </div>
  );
};
export default Layout;
