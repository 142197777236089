import React from "react";

export default function EmailIcon({ className }) {
  return (
    <svg
      width="100%"
      height="5100%12"
      x="0"
      y="0"
      viewBox="0 0 512 512"
      className={className}
    >
      <g>
        <path
          d="M467 76H45C20.137 76 0 96.262 0 121v270c0 24.885 20.285 45 45 45h422c24.655 0 45-20.03 45-45V121c0-24.694-20.057-45-45-45zm-6.302 30L287.82 277.967c-8.5 8.5-19.8 13.18-31.82 13.18s-23.32-4.681-31.848-13.208L51.302 106h409.396zM30 384.894V127.125L159.638 256.08 30 384.894zM51.321 406l129.587-128.763 22.059 21.943c14.166 14.166 33 21.967 53.033 21.967s38.867-7.801 53.005-21.939l22.087-21.971L460.679 406H51.321zM482 384.894 352.362 256.08 482 127.125v257.769z"
          fill="currentColor"
          opacity="1"
        ></path>
      </g>
    </svg>
  );
}
