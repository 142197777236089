import Link from "next/link";
import classNames from "classnames";
import { useRouter } from "next/router";
import CategoryCard from "@/components/ui/category-card";
import ChevronDown from "@/icons/chevron-down";
import { useState } from "react";
const HeaderMenu = ({ data, className, scrollPosition }) => {
  const router = useRouter();
  const [activeSubMenu, setActiveSubMenu] = useState(false);
  const handleMouseEnter = () => {
    setActiveSubMenu(true);
  };

  const handleMouseLeave = () => {
    setActiveSubMenu(false);
  };
  return (
    <>
      <div
        className={classNames(
          `headerMenu flex w-fit items-center justify-center gap-8 h-full`,
          className
        )}
      >
        {data?.map((item) => (
          <div
            className={`menuItem group flex items-center cursor-pointer group/menuItem h-full`}
            key={item.id}
          >
            {item.path ? (
              <Link
                href={item.path}
                onMouseEnter={() => handleMouseEnter()}
                onMouseLeave={() => handleMouseLeave()}
                className={`inline-flex items-center group-hover:text-darkBrown py-1.5 lg:text-lg text-sm font-glacial 
                  ${
                    router?.pathname === item?.path
                      ? "font-bold"
                      : "font-normal"
                  }
              `}
              >
                <span className="capitalize">{item.label}</span>
                {(item?.columns || item.subMenu) && (
                  <ChevronDown className="transition-all duration-300 ease ease-in-out transform group-hover:-rotate-180" />
                )}
              </Link>
            ) : (
              <div className="inline-flex items-center group-hover:text-darkBrown py-1.5 lg:text-lg text-sm font-glacial">
                {item.label}
                {(item?.columns || item.subMenu) && (
                  <ChevronDown className="ease-in-out transform group-hover:-rotate-180 transition-transform duration-500 ease" />
                )}
              </div>
            )}

            {item?.subMenu && Array.isArray(item.subMenu) && (
              <div className="overflow-hidden shrink-0 absolute group-hover/menuItem:h-[220px] h-[0px] w-full left-0 top-[120px] bg-darkBeige opacity-0 group-hover:visible flex flex-col group-hover:opacity-100 px-2 shadow-menu transition-all duration-500 ease-out">
                <div className="py-8 2xl:text-base xl:text-base text-sm text-darkBrown flex items-center w-full h-full group-hover:translate-y-0 -translate-y-10 overflow-hidden group-hover:opacity-100 opacity-0 transition-all duration-500 ease justify-center gap-6">
                  {item.subMenu.map((menu, index) => {
                    return (
                      <CategoryCard key={"category" + index} data={menu} />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default HeaderMenu;
