import SearchIcon from "@/icons/search-icon";
import React from "react";
import cn from "classnames";
import { useUI } from "@/contexts/ui.context";
import { IoCloseOutline } from "react-icons/io5";
import { useForm } from "react-hook-form";
const SearchBox = React.forwardRef(
  ({ className, onSubmit, onClear, ...rest }, ref) => {
    const { closeSearch } = useUI();

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
    return (
      <form
        className={cn(
          "relative pr-12 md:pr-14 bg-white overflow-hidden rounded-md w-full",
          className
        )}
        noValidate
        role="search"
        onSubmit={handleSubmit((data) => onSubmit(data))}
      >
        <label htmlFor="search" className="flex items-center py-0.5">
          <span className="flex items-center justify-center flex-shrink-0 w-12 h-full cursor-pointer md:w-14 focus:outline-none">
            <SearchIcon color="text-heading" className="w-4 h-4" />
          </span>
          <input
            id="search"
            className="w-full h-12 text-sm placeholder-gray-400 outline-none text-heading lg:h-14 lg:text-base"
            placeholder="Search"
            aria-label="Search"
            autoComplete="off"
            name="search"
            type="text"
            ref={ref}
            {...register("search", {
              required: `${"Please provide a search term"}`,
            })}
            {...rest}
          />
        </label>
        <button
          type="button"
          className="absolute top-0 flex items-center justify-center w-12 h-full text-2xl text-gray-400 transition duration-200 ease-in-out outline-none md:text-3xl right-0 md:w-14 hover:text-heading focus:outline-none"
          onClick={closeSearch}
        >
          <IoCloseOutline className="w-6 h-6" />
        </button>
      </form>
    );
  }
);
SearchBox.displayName = "SearchBox";
export default SearchBox;
