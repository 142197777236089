import Link from "next/link";
import EmailIcon from "@/icons/email-icon";
import Subscribe from "./subscribe";
import Linkedin from "@/icons/linkedin";
import Whatsapp from "@/icons/whatsapp";
import Instagram from "@/icons/instagram";

const Footer = () => {
  return (
    <footer className="bg-brown w-full z-[0] lg:px-16 md:px-8 px-4 py-12 rounded-t-[20px]">
      <div className="flex md:flex-row flex-col items-center md:justify-between border-b border-white pb-12 gap-12">
        <p className="text-beige lg:text-[40px] lg:leading-tight md:text-2xl text-2xl font-semibold lg:max-w-[650px] md:max-w-[300px]">
          Explore Our Glassworks. Browse our exclusive range of handcrafted
          glass platters and T-lights to illuminate your spaces.
        </p>
        <div className="flex flex-col gap-2 lg:max-w-[400px]">
          <p className="text-beige lg:text-2xl text-xl font-bold">
            Subscribe to Our Newsletter
          </p>
          <p className="text-beige text-base leading-tight">
            Become a subscriber and be the first to know about new arrivals and
            special promotions.
          </p>
          <Subscribe />
        </div>
      </div>
      <div className="flex md:flex-row flex-col md:justify-between w-full gap-12 mt-12">
        <div className="flex lg:gap-20 justify-between">
          <div className="flex flex-col justify-start items-start gap-3">
            <ColumnHeader>Shop</ColumnHeader>
            <div className="grid grid-cols-2 gap-10">
              <div className="flex flex-col gap-1">
                <InternalLink path="/category/lazy-susan">
                  Lazy Susan
                </InternalLink>
                <InternalLink path="/category/shovel">Shovel</InternalLink>
                <InternalLink path="/category/pebbles">Pebbles</InternalLink>
                <InternalLink path="/category/platter">Platter</InternalLink>
                <InternalLink path="/category/tray">Tray</InternalLink>
              </div>
              <div className="flex flex-col gap-1">
                <InternalLink path="/category/tulip">Tulip</InternalLink>
                <InternalLink path="/category/swirl">Swirl</InternalLink>
                <InternalLink path="/category/table-clock">
                  Table Clock
                </InternalLink>
                <InternalLink path="/category/t-light">T-Light</InternalLink>
              </div>
            </div>
          </div>
          {/* <div className="flex flex-col gap-3 [&>p]:text-beige">
            <ColumnHeader>Enquires</ColumnHeader>
            <p>Address</p>
            <p>Street</p>
            <p>Pincode</p>
            <p>Phone</p>
          </div> */}
          <div className="flex flex-col gap-3">
            <ColumnHeader>Info</ColumnHeader>
            <div className="flex flex-col gap-1">
              <InternalLink path="/about-us">About Us</InternalLink>
              <InternalLink path="/contact-us">Contact Us</InternalLink>
              <InternalLink path="/terms-and-conditions#returns">
                Returns
              </InternalLink>
              <InternalLink path="/terms-and-conditions#shipping">
                Shipping
              </InternalLink>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-12">
          <div className="flex items-center gap-4">
            <SocialButton
              link="https://www.instagram.com/rosebowl.in/profilecard/?igsh=MWFxYnd2bnd2dHFjNQ=="
              title="instagram icon"
            >
              <Instagram className="text-beige w-6" />
            </SocialButton>
            <SocialButton
              link="https://www.linkedin.com/in/rajeev-babbar-366ab78"
              title="linkedin icon"
            >
              <Linkedin className="text-beige w-6" />
            </SocialButton>
            <SocialButton
              link="https://api.whatsapp.com/send?phone=919818314184"
              title="whatsapp icon"
            >
              <Whatsapp className="text-beige w-6" />
            </SocialButton>
            <SocialButton link="mailto:rajeev@rosebowl.in" title="email icon">
              <EmailIcon className="text-beige w-6" />
            </SocialButton>
          </div>
          <p className="text-sm text-beige">
            © 2024 Rose Bowl. All Rights Reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

const SocialButton = ({ link, children, title }) => {
  return (
    <a
      className="cursor-pointer"
      href={link ?? "#"}
      target="_blank"
      aria-label={title}
    >
      {children}
    </a>
  );
};
const ColumnHeader = ({ children }) => {
  return (
    <p className="text-beige md:text-lg text-base font-semibold capitalize text-left">
      {children}
    </p>
  );
};
const InternalLink = ({ path, children }) => {
  return (
    <div className="flex flex-col items-center w-fit justify-center group">
      <Link
        href={path}
        className="text-beige mb-0 leading-none text-sm md:text-base"
      >
        {children}
      </Link>
      <div className="w-[2px] group-hover:w-full bg-beige h-[1px] opacity-0 group-hover:opacity-100 transition-all duration-500 ease"></div>
    </div>
  );
};
