import React from "react";

export default function Linkedin({ className }) {
  return (
    <svg
      width="100%"
      height="100%"
      x="0"
      y="0"
      viewBox="0 0 93.06 93.06"
      className={className}
    >
      <g>
        <path
          d="M11.185.08C5.004.08.001 5.092 0 11.259c0 6.173 5.003 11.184 11.186 11.184 6.166 0 11.176-5.011 11.176-11.184C22.362 5.091 17.351.08 11.185.08zM1.538 30.926h19.287V92.98H1.538zM69.925 29.383c-9.382 0-15.673 5.144-18.248 10.022h-.258v-8.479H32.92v62.053h19.27V62.281c0-8.093 1.541-15.932 11.575-15.932 9.89 0 10.022 9.256 10.022 16.451v30.178H93.06V58.942c0-16.707-3.605-29.559-23.135-29.559z"
          fill="currentColor"
          opacity="1"
          dataOriginal="currentColor"
        ></path>
      </g>
    </svg>
  );
}
