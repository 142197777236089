import Link from "next/link";
import Image from "next/image";
import usePrice from "../../framework/product/use-price";

const SearchProduct = ({ item }) => {
  const { price, basePrice, discount } = usePrice({
    amount: item?.sellingPrice,
    baseAmount: item?.MRP,
    currencyCode: "INR",
  });
  return (
    <Link
      href={`/products/${item?.slug}`}
      className="flex items-center justify-start w-full h-auto group"
    >
      <div className="relative flex flex-shrink-0 w-24 h-24 overflow-hidden bg-gray-200 rounded-md cursor-pointer mr-4">
        <Image
          src={
            item?.images?.data[0]?.attributes?.url ??
            "/assets/placeholder/search-product.svg"
          }
          fill
          loading="eager"
          alt={item.name || "Product Image"}
          className="object-contain bg-gray-200"
          sizes="(max-width: 768px) 500px, (max-width: 1200px) 600px, 500px"
        />
      </div>
      <div className="flex flex-col w-full overflow-hidden">
        <h3 className="mb-2 text-sm truncate text-heading">{item.name}</h3>
        <div className="text-sm font-semibold text-heading">from {price}</div>
      </div>
    </Link>
  );
};

export default SearchProduct;
